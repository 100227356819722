<div
    class="bg-dark d-flex align-items-center justify-content-center w-100 h-100"
>
    <div
        class="card fat-border-black fat-border card-border d-flex align-items-center justify-content-between"
    >
        <div class="w-100 p-5">
            <div #text></div>
            <div #text1></div>
            <div #text2></div>
            <div #text3 class="my-3"></div>
        </div>
        <div class="donate align-items-center d-flex flex-nowrap">
            <div
                class="btn fat-border fat-border-black btn-dark mx-3 my-3 cursor-pointer"
                (click)="goTo('')"
            >
                <- Back
            </div>
            <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
            >
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="AK4KMBBBCV74Y" />
                <input type="hidden" name="currency_code" value="CAD" />
                <input
                    type="image"
                    src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                    border="0"
                    [style.height.px]='39'
                    class='cursor-pointer'
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                />
                <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/en_CA/i/scr/pixel.gif"
                    width="1"
                    height="1"
                />
            </form>

            <div
                class="btn fat-border-black fat-border mx-3 my-3 cursor-pointer"
                (click)="goToUrl('https://github.com/ChloeStevovitch/colorTracker')"
            >
                Go to repo ->
            </div>
        </div>
    </div>
</div>
