<div class="grid-content">
    <div class="left"></div>

    <div class="right my-5">
        <div class="d-flex flex-column">
            <span class="h3 mx-3 days-height">Period</span>
            <div class="d-flex mb-3">
                <div
                    class="btn fixed-width-small fat-border fat-border-black mx-3"
                    [class.disabled]="activeView === View.Year"
                    [class.bg-dark]="activeView === View.Year"
                    [class.text-light]="activeView === View.Year"
                    [class.cursor-pointer]="activeView !== View.Year"
                    (click)="handleChangeView(View.Year)"
                >
                    Year
                </div>
                <div
                    class="btn fixed-width-small fat-border fat-border-black mx-3"
                    [class.disabled]="activeView === View.Month"
                    [class.bg-dark]="activeView === View.Month"
                    [class.text-light]="activeView === View.Month"
                    [class.cursor-pointer]="activeView !== View.Month"
                    (click)="handleChangeView(View.Month)"
                >
                    Month
                </div>
                <div
                    class="btn fixed-width-small fat-border fat-border-black mx-3"
                    [class.cursor-pointer]="activeView !== View.Week"
                    [class.bg-dark]="activeView === View.Week"
                    [class.text-light]="activeView === View.Week"
                    (click)="handleChangeView(View.Week)"
                    [class.disabled]="activeView === View.Week"
                >
                    Week
                </div>
            </div>
            <span class="h3 mx-3 days-height">Legend</span>

            <app-color-picker
                *ngFor="let el of retrievedColorScale; let h = index"
                (colorPickerChange)="
                    updateColor($event, 'colorScale', 'retrievedColorScale', h)
                "
                (legendUpdate)="setLegend($event)"
                (deleteEvent)="handleDelete($event)"
                [index]="h"
                [color]="el.color"
                [retrievedCount]="_.countBy(retrievedData)"
                [legend]="el.legend ? el.legend : 'Level ' + h"
                class="mb-2 mx-3"
            ></app-color-picker>
            <div
                class="btn cursor-pointer btn-light fat-border fat-border-black fixed-width mt-4 mx-3"
                (click)="addColor()"
            >
                add a color
            </div>
            <div
                class="btn my-5 cursor-pointer btn-light fat-border fat-border-black fixed-width mx-3"
                (click)="toggleOptions()"
            >
                {{ options ? 'Less options ▲' : 'Advanced options ▼' }}
            </div>
            <div *ngIf="options">
                <span class="h3 my-3 mx-3 days-height">Background</span>
                <app-color-picker
                    (colorPickerChange)="
                        updateColor(
                            $event,
                            'backgroundColor',
                            'retrievedBackgroundColor'
                        )
                    "
                    [color]="retrievedBackgroundColor"
                    class="mb-2 mx-3"
                ></app-color-picker>

                <span class="h3 my-3 mx-3 days-height">Text</span>
                <app-color-picker
                    (colorPickerChange)="
                        updateColor($event, 'textColor', 'retrievedTextColor')
                    "
                    [color]="retrievedTextColor"
                    class="mb-2 mx-3"
                    [textLight]="true"
                ></app-color-picker>
                <span class="h3 my-3 mx-3 days-height">Grid</span>
                <app-color-picker
                    (colorPickerChange)="
                        updateColor(
                            $event,
                            'borderColor',
                            'retrievedBorderColor'
                        )
                    "
                    [color]="retrievedBorderColor"
                    class="mb-2 mx-3"
                    [textLight]="true"
                ></app-color-picker>
                <span class="h3 my-3 mx-3 days-height">Data</span>
                <div class="d-flex flex-column">
                    <div
                        class="btn btn-light cursor-pointer fat-border fat-border-black fixed-width mb-3 mx-3"
                        (click)="toggleEdition()"
                    >
                        {{ !editionMode ? 'show data' : 'hide data' }}
                    </div>
                    <textarea
                        class="formControl mx-3 mb-3 fixed-width-edition"
                        (blur)="handleNewData($event)"
                        *ngIf="editionMode"
                        [value]="JSON.stringify(retrievedTracker)"
                    ></textarea>
                    <div
                        class="btn fat-border fat-border-black fixed-width mb-3 mx-3 cursor-pointer"
                        (click)="reset()"
                    >
                        /!\ reset data
                    </div>
                    <div
                        class="btn fixed-width fat-border fat-border-black mx-3 cursor-pointer"
                        (click)="handleTrackerDelete()"
                    >
                        /!\ delete tracker
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="activeView === View.Year"
        class="my-5 middle d-flex flex-column"
    >
        <div class="historic d-flex flex-nowrap mx-auto align-items-center">
            <div
                class="btn fixed-width-small fat-border fat-border-black mx-3"
                (click)="setModifier('yearModifier', -1)"
            >
                <
            </div>
            <div
                class="btn fixed-width-small fat-border fat-border-black mx-3"
                (click)="setModifier('yearModifier', +1)"
            >
                >
            </div>
            <span class="mx-2">{{ year + yearModifier }} </span>
        </div>
        <div class="d-flex flex-nowrap monthsNameContainer">
            <div class="days-numbers"></div>
            <div *ngFor="let month of monthsName" class="month days monthNames">
                {{ month }}
            </div>
        </div>
        <div class="d-flex flex-nowrap">
            <div class="d-flex flex-column">
                <div
                    *ngFor="let el of initTable(31); let l = index"
                    class="days-numbers"
                >
                    {{ l + 1 }}
                </div>
            </div>
            <div
                *ngFor="let month of monthsName; let i = index"
                class="d-flex flex-column month"
            >
                <div
                    *ngFor="
                        let el of initTable(getMonthLength(yearModifier, i));
                        let j = index
                    "
                    class="days"
                >
                    <div
                        class="cursor-pointer h-100 w-100 fat-border"
                        (click)="handleClick(year + yearModifier, month, j)"
                        (contextmenu)="
                            handleRightClick(year + yearModifier, month, j)
                        "
                        [style.backgroundColor]="
                            getColor(year + yearModifier, month, j)
                        "
                        [style.borderColor]="retrievedBorderColor"
                    ></div>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div
                    *ngFor="let el of initTable(31); let l = index"
                    class="days-numbers"
                >
                    {{ l + 1 }}
                </div>
            </div>
        </div>
        <div class="d-flex flex-nowrap monthsNameContainer">
            <div class="days-numbers"></div>
            <div *ngFor="let month of monthsName" class="month days monthNames">
                {{ month }}
            </div>
        </div>
    </div>
    <div *ngIf="activeView === View.Week" class="my-5 d-flex flex-column">
        <div class="historic d-flex flex-nowrap mx-auto">
            <div
                class="btn fixed-width-small fat-border fat-border-black mx-3"
                (click)="setModifier('weekModifier', -1)"
            >
                <
            </div>
            <div
                class="btn fixed-width-small fat-border fat-border-black mx-3"
                (click)="setModifier('weekModifier', +1)"
            >
                >
            </div>
        </div>
        <div class="d-flex mx-auto my-2">
            <div>
                <div
                    *ngFor="let day of getWeekDetails(weekModifier)"
                    class="daysNames"
                >
                    <span>{{ getDayName(day) }}</span>
                    - <span>{{ getDayNumber(day) }}</span>
                    <span class="ml-2 text-uppercase">{{ getMonth(day) }}</span>
                    <span class="my-2"> {{ getYear(day) }}</span>
                </div>
            </div>
            <div>
                <div
                    *ngFor="let day of getWeekDetails(weekModifier)"
                    class="days mx-3"
                >
                    <div
                        class="cursor-pointer h-100 w-100 fat-border"
                        [style.borderColor]="retrievedBorderColor"
                        [style.backgroundColor]="
                            getColor(
                                getYear(day),
                                getMonth(day),
                                getDayNumber(day) - 1
                            )
                        "
                        (contextmenu)="
                            handleRightClick(
                                getYear(day),
                                getMonth(day),
                                getDayNumber(day) - 1
                            )
                        "
                        (click)="
                            handleClick(
                                getYear(day),
                                getMonth(day),
                                getDayNumber(day) - 1
                            )
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="activeView === View.Month"
        class="my-5 d-flex flex-column flex-nowrap"
    >
        <div class="historic d-flex flex-nowrap mx-auto">
            <div
                class="btn fixed-width-small fat-border fat-border-black mx-3"
                (click)="setModifier('monthModifier', -1)"
            >
                <
            </div>
            <div
                class="btn fixed-width-small fat-border fat-border-black mx-3"
                (click)="setModifier('monthModifier', +1)"
            >
                >
            </div>
        </div>
        <div class="d-flex mx-auto my-2">
            <div>
                <div
                    *ngFor="let day of getMonthDetails(monthModifier)"
                    class="daysNames"
                >
                    <span>{{ getDayName(day) }}</span>
                    - <span>{{ getDayNumber(day) }}</span>

                    <span class="ml-2 text-uppercase">{{ getMonth(day) }}</span>
                    <span class="my-2"> {{ getYear(day) }}</span>
                </div>
            </div>
            <div>
                <div
                    *ngFor="let day of getMonthDetails(monthModifier)"
                    class="days mx-3"
                >
                    <div
                        class="cursor-pointer h-100 w-100 fat-border"
                        [style.borderColor]="retrievedBorderColor"
                        [style.backgroundColor]="
                            getColor(
                                getYear(day),
                                getMonth(day),
                                getDayNumber(day) - 1
                            )
                        "
                        (contextmenu)="
                            handleRightClick(
                                getYear(day),
                                getMonth(day),
                                getDayNumber(day) - 1
                            )
                        "
                        (click)="
                            handleClick(
                                getYear(day),
                                getMonth(day),
                                getDayNumber(day) - 1
                            )
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
</div>
