<div class="d-flex mb-3 align-items-center w-100 flex-wrap">
    <input
        *ngIf="index || legend"
        type="text"
        class="form-control mr-3 fixed-width-legend"
        [value]="legend"
        (blur)="onLegendUpdate($event)"
    />
    <div class="d-flex flex-nowrap">
        <input
            class="btn fat-border my-2 fat-border-black cursor-pointer fixed-width"
            [class.text-light]="textLight"
            [(colorPicker)]="color"
            [value]="color"
            readonly
            cpPosition="bottom"
            (colorPickerClose)="onColorPicked($event)"
            [style.background]="color"
        />
        <div
            class="btn d-flex align-items-center"
            [class.hidden]="index <= 1"
            (click)="index <= 1 ? '' : handleDelete()"
        >
            <span>x</span>
        </div>
    </div>
    <div *ngIf="index === 0" class="text-center count mx-2 d-flex flex-nowrap">
        <span class="big"> ∞</span>
    </div>
    <div
        *ngIf="index > 0"
        class="small text-center count mx-2 d-flex flex-nowrap"
    >
        {{ retrievedCount[index] ? retrievedCount[index] : 0 }}
    </div>
</div>
